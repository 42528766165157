// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Color Variables
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'CollapsibleTextArea Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'FrontPageComponentTest',
    pageDescription: 'Tester components på frontpage',
    bannerImage: null, // accepts null
    bannerContent: 'Banner text accepts HTML', // accepts null
    bottomContent: [
      // CollapsibleFAQ inside BlockArea
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aquaLight,
        },
        componentChildren: [
          {
            componentName: 'CollapsibleTextArea',
            componentData: {
              id: 'first',
              openText: 'Læs mere',
              closeText: 'Læs mindre',
              text: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<h2>Header Text</h2><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus et netus. Aenean euismod elementum nisi quis eleifend. Eget duis at tellus at urna condimentum. Orci porta non pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
                  },
                },
              ],
            },
            componentChildren: [
              {
                componentName: 'HtmlRender',
                componentData: {
                  html:
                    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
                },
              },
            ],
          },
          {
            componentName: 'Columns',
            componentData: {
              columns: 2,
              gap: '40',
              minWidth: '768px',
            },
            componentChildren: [
              {
                componentName: 'CollapsibleTextArea',
                componentData: {
                  id: 'second',
                  openText: 'Læs mere',
                  closeText: 'Læs mindre',
                  text: [
                    {
                      componentName: 'HtmlRender',
                      componentData: {
                        html:
                          '<h2>Header Text</h2><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus et netus. Aenean euismod elementum nisi quis eleifend. Eget duis at tellus at urna condimentum. Orci porta non pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
                      },
                    },
                  ],
                },
                componentChildren: [
                  {
                    componentName: 'HtmlRender',
                    componentData: {
                      html:
                        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
                    },
                  },
                ],
              },
              {
                componentName: 'CollapsibleTextArea',
                componentData: {
                  id: 'third',
                  openText: 'Læs mere',
                  closeText: 'Læs mindre',
                  text: [
                    {
                      componentName: 'HtmlRender',
                      componentData: {
                        html:
                          '<h2>Header Text</h2><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus et netus. Aenean euismod elementum nisi quis eleifend. Eget duis at tellus at urna condimentum. Orci porta non pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
                      },
                    },
                  ],
                },
                componentChildren: [
                  {
                    componentName: 'HtmlRender',
                    componentData: {
                      html:
                        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    // CollapsibleFAQ
    {
      componentName: 'CollapsibleTextArea',
      componentData: {
        id: '123',
        openText: 'Læs mere',
        closeText: 'Læs mindre',
        text: [
          {
            componentName: 'HtmlRender',
            componentData: {
              html: '<h3>Header Text</h3>',
            },
          },
        ],
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
          },
        },
      ],
    },
  ],
};

export default Data;
